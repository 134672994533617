<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订单编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.orderNo"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="操作人:"
                    label-for="sales_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="sales_name"
                      size="sm"
                      v-model="condition.creatorName"
                      name="salesName"
                      @click="showSingleModal('销售员')"
                      readonly
                      placeholder="点击搜索操作人"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订单状态:"
                    label-for="orderStatus"
                    label-size="sm"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('contract_status')"
                      v-model="condition.status"
                      name="orderStatus"
                      class="select-size-sm"
                      placeholder="请选择订单状态"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="操作日期:"
                    label-for="length"
                    label-size="sm"
                    class="mb-1"
                >
                  <flat-pickr
                      v-model="condition.addTime"
                      class="form-control"
                      :config="{ mode: 'range'}"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :order-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :order-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <template #cell(add_time)="data">
          {{ toTime(data.item.add_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(memo)="data">
          <div :id="`remark-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
            <feather-icon icon="EditIcon" @click="showRemark(data.item)"/>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('contract_status', data.item.status_before)}`"
          >
            {{ getCodeLabel("contract_status", data.item.status_before) }}
          </b-badge>
          <feather-icon
              icon="ArrowRightIcon"
              size="21"
          />
          <b-badge
              pill
              :variant="`light-${getCodeColor('contract_status', data.item.status)}`"
          >
            {{ getCodeLabel("contract_status", data.item.status) }}
          </b-badge>
        </template>


        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>

        <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
        >

          <!-- :userDepartment="userDepartment"  判断负责人所属部门-->
          <user-select
              ref="userSelect" :userDepartment="userDepartment" v-if="modalName === '销售员' "
          >
          </user-select>

        </b-modal>

        <b-modal
            id="modal-remark"
            ok-only
            ok-title="确认"
            @ok="saveRemark"
            cancel-title="取消"
            centered
            size="lg"
            title="修改备注"
            ref="remarkModal"
        >
          <b-card :header="`编号：${selectedItem.order_no}`">
            <b-form-textarea
                id="textarea"
                v-model="remarkInfo"
                rows="3"
                max-rows="6"
            />
          </b-card>
        </b-modal>

      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import salesorderchecklogUseList from './salesorderchecklogUseList'
import salesorderchecklogStore from './salesorderchecklogStore'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import Ripple from "vue-ripple-directive"
import flatPickr from 'vue-flatpickr-component'
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    UserSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('salesorderchecklog/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    showRemark(item) {
      this.selectedItem = item
      this.remarkInfo = item.memo
      this.$refs['remarkModal'].show()
    },
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.condition={}
      // store.commit('salesorder/updateCondition', this.state.condition)
      this.refetchData();
    },
    showSingleModal(modalName) {
      if (modalName === '渠道方') {
        this.companyType = 'ChannelCustomer'
      } else if (modalName === '销售主体') {
        this.companyType = 'Group'
      }
      this.modalName = modalName
      this.$refs['singleModal'].show()
    },

    onSelectSingle() {
      let data = {}
      switch (this.modalName) {
        case '销售员': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.condition.creatorName = data[0].full_name;
            this.condition.creatorId = data[0].user_id;
            this.$forceUpdate()
          }
          break;
        }
        default: {
          break
        }
      }
    },
  },
  data() {
    return {
      advanced_search_modal: false,
      modalName: '',
      userDepartment: "sales",
      show:true,
      selectedItem: {},
      remarkInfo: null,
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('salesorderchecklog')) store.registerModule('salesorderchecklog', salesorderchecklogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesorderchecklog')) store.unregisterModule('salesorderchecklog')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const saveRemark = function () {
      this.selectedItem.memo = this.remarkInfo
      store.dispatch('salesorderchecklog/save', this.selectedItem).then(res => {
        if (res.data.code === 0) {
          toast.success("修改成功")
        } else {
          toast.error("修改失败")
        }
        refetchData()
      })
    }

    const {
      condition,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = salesorderchecklogUseList()

    return {
      condition,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime, toDate,
      // UI
      advanced_search,
      saveRemark,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
